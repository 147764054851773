
/* // mobile devices (phones) */
@tailwind base;
@tailwind components;
@tailwind utilities;

@media (min-width: 0px) {
  .font-poppins{
    font-family: 'Poppins';
  }
  .fs-7
  {
    font-size: 12px;
  }
  .fs-8{
    font-size: 11px;
  }
  .fs-8{
    font-size: 10px;
  }
  
  .form-field {
    padding: 10px;
    border:1px solid #DDE8FE;
    border-radius: 10px;
    display: block;
    width: 100%;
    margin-top: -20px;
    position: relative;
  }
  .button
  {
    border-radius: 10px;
    padding: 10px 50px;
    border: 0px;
    color: white;
    text-decoration: none;
  }
  .link
  {
    padding: 7px 50px;
  }
  .btn-primer:hover{
    background-color: gray;
    color:white;
  }
  .btn-primer
  {
    background-color: #627AEE !important;
    color: white;
  }
  .input-label-mobile
  {
    z-index: 1;
    position: relative;
    background-color: white;
    margin-left: 20px;
    padding: 0px 10px;
    color: gray;
  }
  .logo-home{
    height: 150px;
  }
  .img-user-home{
    width: 80%;
  }
  .wrap-title-page{
    width:max-content;
    margin: 0px auto;
  }
  .poppins-primary
  {
    font-family: 'Poppins', sans-serif;
    color: #627AEE;
    font-size: 40px;
  }
  .poppins-second-primary
  {
    font-family: 'Poppins', sans-serif;
    color: #627AEE;
    font-size: 30px;
    margin-left: 10px;
    margin-top: -10px;
    display: block;
    letter-spacing: 15px;
  }
  .input-field{
    margin-top: -20px;
  }
  .wrap-bt-tracking
  {
    width: 100%;
    background-color: white;
    padding: 20px 30px;
  }
  .text-detail-document
  {
    font-size: 14px;
  }
  .text-detail-tracking
  {
    margin-left: 15px;
  }
  .under-line
  {
    /* width: 105%;
    margin-left: -15px; */
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
    height: 2px;
    background-color: #CBCBCB;
  }
  .f-detail
  {
    color: #3E566F;
  }
  .wrap-mobile{
      max-width: 1000px;
      background-color: white;
      display: block;
      margin: 0px auto;
      padding: 0px;
      height: 100vh;
      /* overflow: hidden; */
      position: relative;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
  .wrap-mobile::-webkit-scrollbar {
    display: none;
  }
  .area-scroll{
    height: 90%;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .area-scroll::-webkit-scrollbar {
    display: none;
  }
  .wrap-content{
      padding: 30px 20px;
  }
  .wrap-document-tracking
  {
      background-color: #f6f6f6;
  }
  .wrap-circle-tracking
  {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      padding: 4px 5px;
  }
  .circle-tracking
  {
      width: 15px;
      height: 15px;
      border-radius: 50%;
  }
  .line-tracking
  {
      width: 4px;
      height: 50px;
      margin: 5px 0px;
      background-color: rgb(210, 206, 206);
  }
  .circle-active
  {
      background-color: #627AEE;
  }
  .text-primer
  {
      color: #627AEE;
  }
  .bg-prim{
    background-color: #627AEE;
  }
  .bg-primer{
      background-color: #c4ccf1;
  }
  .bg-second{
    background-color: rgb(187, 184, 184);
  }
  .bg-gray{
      background-color: gray;
  }
  .text-primer:hover
  {
      color: #627AEE;
  }
  .text-secondary:hover
  {
    color: #627AEE !important;
  }
  .wrap-circle-active
  {
      background-color: #CCDDFF;
  }
  .wrap-btn
  {
      width: max-content;
      margin: 25px auto;
  }
  .wrap-item-nav-regis
  {
    width: max-content;
    display: block;
    margin: 40px auto;
  }
  .wrap-item-nav-regis-active
  {
    border-bottom: 1px solid #627AEE;
  }
  .input-search-document
  {
    padding: 10px 35px;
    width: 100%;
    border-radius:20px;
    border: 1px solid #627AEE;
  }
  .btn-submit-search-document
  {
    border:0px;
    border-radius: 15px;
    padding: 11px;
    width: 60px;
    margin-left: -15px;
  }
  .body-result-search
  {
    background-color: rgb(239, 236, 236);
    padding: 20px;
  }
  .card-count
  {
    background-color: white;
    border-radius: 20px;
    padding: 10px;
    position: relative
  }
  .wrap-body
  {
    width: 100%;
  }
  .eye{
    margin-left: -30px;
    z-index: 1;
    position: relative;
    cursor: pointer;
  }
  .wrap-top-user{
    background-color: #627AEE;
    width: 100%;
  }
  .avatar-top-user{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 2px solid white;
  }
  .wrap-bottom-nav{
    background-color: white;
    /* padding: ; */
    height: 10%;
    padding-top: 10px;
    width: 100%;
  }
  .wrap-element-bot-nav{
    margin-left: 20px;
    margin-right: 20px;
  }
  .wrap-top-title{
    background-color: #627AEE;
  }
  select{
    background-color: white;
  }

  .wrap-img-top-pdf{
    background-color: #627AEE;
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
    height: 200px;
    padding-top: 50px;
  }

  .text-top-pdf{
    color:white;
    font-size: 200%;
    font-weight: bold;
  }
  .wrap-box{
    width: 100%;
  }
  .box-1{
    width: 70%;
    position: relative; 
    padding: 30px 50px;
    /* display: block; */
    margin: 0px auto;
    border-radius: 30px;
    border: 1px solid rgb(210, 207, 207);
    margin-top: -60px;
    background-color: white;
  }

  .box-2{
    width: 70%;
    position: relative; 
    padding: 30px 50px;
    /* display: block; */
    margin: 0px auto;
    border-radius: 30px;
    border: 1px solid rgb(210, 207, 207);
    margin-top: 40px;
    background-color: white;
  }

  .title-company-name, .title-jenis-ls{
    font-size: 80%;
    font-weight: bold;
    color: gray;
  }
  .company-name{
    margin-top: -10px;
    font-size: 130%;
    font-weight: bold;

  }
  .jenis-ls{
    font-size: 110%;
    margin-top: -10px;
    letter-spacing: 5px;
    font-weight: bold;
    color: gray;
  }
  .title-number{
    font-size: 90%;
    font-weight: bold;
    color: gray;
  }
  .number{
    font-size: 150%;
    margin-top: -10px;
    letter-spacing: 5px;
    font-weight: bold;
  }
  .wrap-body-document-validation{
    background-color: rgb(249, 245, 245);;
  }
  .wrap-card-document{
    
  }
  .card-document{
    background-color: white;
    border-radius: 10px;
    padding: 10px;
  }
  .document-number{
    letter-spacing: 2px;
  }
  .wrap-input-validation{
    border: 1px solid #a6aed8;
    border-radius: 10px;
    padding: 10px;
  }
  .select-validation{
    border: 0px;
    width: 100%;
  }
  .desc-validation{
    background-color: rgb(239, 235, 235);
    border: 0px;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
  }

  .select-validation:valid{
    color: rgb(174, 171, 171);
  }
  .arrow-track{
    width: 100%;
  }
  .wrap-radio{
    border:1px solid #DDE8FE;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
  }
  .wrap-header-app{
    /* width: 100%; */
    right: 0px;
    top:0px;
    position: relative;
    background-color: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .wrap-outlet-index{
    height: 90%;
    overflow: scroll;
  }
  .wrap-outlet-index::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .wrap-outlet-index {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .wrap-card-filter{
    border: 1px solid rgb(192, 191, 191);
    padding: 3px 10px;
    border-radius: 10px;
    width: 100%;
    position: relative;
  }
  .chevron-filter-icon{
    position: absolute;
    right: 10px;
    top: 9px;
  }
  .wrap-option-filter{
    position: absolute;
    width: 100%;
    z-index: 1;
    left:0px;
    bottom:0px;
    /* max-height: 70vh; */
  }
  .card-body-filter{
    max-height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .card-body-filter::-webkit-scrollbar {
    display: none;
  }
  .outsider-filter{
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
  .card-filter{
    background-color: white;
    max-height: 100vh;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .grid-two {
    display: grid;
    grid-template-columns: auto auto;
  }
  .grid-two-column {
    display: grid;
    grid-template-columns: auto auto;
  }
  .grid-item {
    padding: 2px;
  }
  .status-document-success{
    background-color: rgb(152, 230, 152);
    color: green !important;
    font-size: 9px !important;
  }
  .status-document-pending{
    background-color: rgb(212, 209, 243);
    color: rgb(67, 57, 91) !important;
    font-size: 9px !important;
  }
  .status-document-failed{
    background-color: rgb(230, 157, 157);
    color: red !important;
    font-size: 9px !important;
  }
  .search-document{
    border-radius: 20px !important;
  }
  .search-document::-webkit-input-placeholder{
    font-size: 10px !important;
  }
  .search-document::-webkit-input-placeholder { /* WebKit browsers */
    font-size: 12px;
    font-weight: bold;
  }

  .search-document:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      font-size: 12px;
      font-weight: bold;
  }

  .search-document::-moz-placeholder { /* Mozilla Firefox 19+ */
      font-size: 12px;
      font-weight: bold;
  }

  .search-document:-ms-input-placeholder { /* Internet Explorer 10+ */
      font-size: 12px;
      font-weight: bold;
  }
  .wrap-card-track{
    width: 100%;
    overflow: scroll;
    scrollbar-width: none;  /* Firefox */
  } 
  .wrap-card-track::-webkit-scrollbar {
    display: none;
  }
  .wrap-wrap{
    width: max-content;
  }
  .body-track{
    width: max-content;
  }
  .wrap-track{
    border-top: 1px solid rgb(196, 193, 193);
    padding-top: 20px;
  }
  .wrap-item-filter{
    padding: 10px;
    border-top: 1px solid rgb(223, 219, 219);
  }
  .sidebar-mobile{
    padding: 10px;
    height: 10%;
    /* position: absolute; */
  }
  .btn-bars{
    align-items: right;
    right: 0px;
    border: 0px;
    background-color: rgba(0, 0, 0, 0);
    color: black;
  }
  .text-first-app{
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 10px;
  }
  .text-second-app{
    font-size: 25px;
    letter-spacing: 10px;
  }
  .acronym-app{
    font-size: 16px;
  }
  .wrap-sidebar{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
    top: 0px;
    left: 0px;
  }
  .sidebar-app{
    background-color: white;
    height: 100%;
    width: 80%;
    overflow:scroll;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
  }
  .sidebar-app::-webkit-scrollbar {
    display: none;
  }
  .card-count-holder{
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    padding: 0px;
    scrollbar-width: none;
  }
  .card-count-holder::-webkit-scrollbar {
    display: none;
  }
  .wrap-card-count{
    display: inline-block;
    width: 200px;
    /* width: max-content; */
    padding: 10px;
  }
  .wrap-data-statistik{
    background-color: #627AEE;
    padding: 0px;
  }
  .wrap-link-sidebar{
    padding: 10px 17px;
    /* border-top: 1px solid rgb(205, 203, 203); */
    border-bottom: 0.1px solid rgb(241, 238, 238);
    border-width: 100%;
    position: relative;
    width: 100% !important;
  }
  .link-sidebar{
    color: gray;
    text-decoration: none;
  }
  .icon-link-sidebar{
    position: absolute;
    top: 16px;
    right: 10px;
  }
  #wrap-company-name{
    background-color: #f1ecec;
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
    max-height: 100px;
    overflow: scroll;
    scrollbar-width: 1px;
  }
  #wrap-company-name::-webkit-scrollbar {
    display: block;
  }
  .input-bg-secondary{
    background-color: #9f9fa0;
  }
}
/* // Small devices (landscape phones, 350px and up) */
@media (min-width: 350px) {
  .logo-home{
    height: 350px;
  }
  .sidebar-mobile{
    padding: 10px;
    height: 5%;
    /* position: absolute; */
  }
  .area-scroll{
    height: 95%;
  }
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .logo-home{
    height: 350px;
  }
  .text-first-app{
    font-size: 50px;
    letter-spacing: 10px;
  }
  .text-second-app{
    font-size: 50px;
    letter-spacing: 10px;
  }
  .acronym-app{
    font-size: 21px;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .wrap-content{
      padding: 30px 100px;
  }
  .wrap-outlet-index{
    height: 90vh;
  }
  .logo-home{
    height: 500px;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .wrap-body
  {
    background-color: rgb(236, 230, 230);
    width: 100%;
    /* min-height: 1200px; */
  }
  .logo-home{
    height: 550px;
  }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  }

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {  }